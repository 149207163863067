import styled from "styled-components";

import { medias } from "styles";

export const Container = styled.div`
  color: #74e601;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 40px;

  margin-top: 45px;
  ${medias.greaterThan("sm")`
    justify-content: flex-start;
    padding: 0;
  `}
`;

export const Ball = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(116, 230, 1, 0.2);
  margin-right: 8px;
`;
