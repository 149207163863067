import styled from "styled-components";

import { medias } from "styles";

export const Container = styled.header`
  position: relative;

  width: 100%;

  padding: 40px 35px 120px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: ${({ config }) => config?.background?.primary};
`;

export const Logo = styled.img`
  margin-bottom: 50px;
`;

export const Title = styled.h1`
  font-size: 21px;
  font-weight: 700;
  color: #2f5768;
  text-align: center;
`;

export const Help = styled.a`
  position: absolute;
  top: 40px;
  left: 35px;

  display: flex;
  align-items: center;

  font-size: 12px;
  color: #7d8898;
  cursor: pointer;

  > span {
    display: none;

    ${medias.greaterThan("sm")`
      display: block;
    `}
  }

  > svg {
    margin-right: 8px;
  }

  :hover {
    opacity: 0.8;
  }
`;
