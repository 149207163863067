import React from "react";
import { useSelector } from "react-redux";

import { Icons, Spinner } from "components";

import {
  Container,
  Ball,
  variants,
  Loader,
  variantsLoader,
  variantesIcon,
  Icon
} from "./styles";

function Button({ isLoading, isSuccess, ...props }) {
  const { loadingPost, successPost } = useSelector(state => state.weblink);

  return (
    <Container {...props} isLoading={loadingPost} isSuccess={successPost}>
      <Ball
        variants={variants}
        animate={loadingPost ? "active" : "initial"}
        transition={{ ease: [0.19, 1, 0.22, 1] }}
      />
      <Icon
        variants={variantesIcon}
        animate={loadingPost ? "hidden" : "initial"}
      >
        <Icons name="check" size={22} />
      </Icon>
      {loadingPost && (
        <Loader
          variants={variantsLoader}
          animate={loadingPost ? "active" : "initial"}
        >
          <Spinner color="#fff" size="1rem" />
        </Loader>
      )}
      {!successPost && <span>Finalizar compra</span>}
    </Container>
  );
}

export default Button;
