import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import { actions } from "store/reducers/notifications";

import { history } from "store";

import * as Pages from "pages";

import "react-notifications/lib/notifications.css";

function Router() {
  const dispatch = useDispatch();

  const { notifications, weblink } = useSelector(state => state);

  useEffect(() => {
    if (notifications.show)
      NotificationManager[notifications.type](
        notifications.message,
        "",
        5000,
        () => {
          dispatch(
            actions.setNotification({
              show: false,
              message: "",
              type: ""
            })
          );
        }
      );
  }, [notifications]);

  return (
    <>
      <Helmet>
        <title>{weblink?.data?.config?.title || "Porto Seguro"}</title>
      </Helmet>
      <NotificationContainer />
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/sucesso" exact={true} component={Pages.Success} />
          <Route path="/:uid" exact={true} component={Pages.Weblink} />
          <Route path="*" component={Pages.Error} />
        </Switch>
      </ConnectedRouter>
    </>
  );
}

export default Router;
