import React from "react";
import { useSelector } from "react-redux";

import { Spinner } from "components";

import logo from "assets/img/logo.svg";

import { replaceByTag } from "helpers/text";

import { Container, Logo, Title } from "./styles";

function Header({ isSuccess, isWeblink, noLoading }) {
  const { error, data, loading, messageSuccess } = useSelector(
    state => state.weblink
  );

  return (
    <Container config={data?.config}>
      <Logo src={data?.config?.logo || logo} />
      {!noLoading && <>{loading && <Spinner />}</>}
      {error && <Title>{error}</Title>}

      {isSuccess && messageSuccess ? (
        <Title>{messageSuccess}</Title>
      ) : (
        isSuccess &&
        data.customer &&
        (data?.feedback?.success || data?.config?.feedback?.success ? (
          <Title>
            {replaceByTag(
              "-customer-",
              data?.feedback?.success || data?.config?.feedback?.success,
              data.customer
            )}
          </Title>
        ) : (
          <Title>{data.customer}, transação efetuada com sucesso.</Title>
        ))
      )}

      {isWeblink &&
        data.customer &&
        (data?.feedback?.initial || data?.config?.feedback?.initial ? (
          <Title>
            {replaceByTag(
              "-customer-",
              data?.feedback?.initial || data?.config?.feedback?.initial,
              data.customer
            )}
          </Title>
        ) : (
          <Title>
            {data.customer}, falta pouco para finalizar sua transação.
          </Title>
        ))}
    </Container>
  );
}

export default Header;
