import styled from "styled-components";

import { medias } from "styles";

export const Container = styled.table`
  width: 100%;

  background: #fff;

  thead {
    background: ${({ config }) => config?.background?.secondary};

    tr {
      th {
        height: 30px;
        padding-left: 10px;
        padding-right: 10px;

        color: #7d8898;
        font-size: 9px;
        font-weight: 500;

        ${medias.greaterThan("sm")`font-size: 13px;`};

        :nth-child(1),
        :nth-child(3) {
          text-align: left;

          ${medias.greaterThan("sm")`
            height: 50px;
            padding-left: 25px;
          `}
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 15px 10px;

        color: #2f5768;
        font-weight: 700;
        font-size: 10px;

        :nth-child(2) {
          text-align: center;
        }

        ${medias.greaterThan("sm")`
            padding: 15px 25px;

            font-size: 15px;
        `}
      }
    }
  }

  tfoot {
    tr {
      td {
        padding: 0 10px;
        height: 30px;

        border-top: solid 1px #f4f4f4;

        ${medias.greaterThan("sm")`
            padding: 0 25px;
            height: 50px;
        `}

        small {
          color: #a0a8ac;
          font-weight: 500;
          font-size: 8px;

          ${medias.greaterThan("sm")`
              font-size: 8px;
            `}
        }

        [data-price] {
          font-weight: 700;
          font-size: 10px;
          color: #2f5768;

          ${medias.greaterThan("sm")`
              font-size: 15px;
            `}
        }

        [data-highlight] {
          cursor: pointer;
          display: flex;
          align-items: center;

          font-size: 9px;
          font-weight: 700;
          color: #00a0e6;

          ${medias.greaterThan("sm")`
              font-size: 11px;
            `}

          > svg {
            margin-right: 8px;

            ${medias.lessThan("sm")`
              width: 10px;
              height: 10px;
            `}
          }
        }

        [data-coupon] {
          cursor: pointer;
          display: flex;
          align-items: center;

          font-size: 9px;
          font-weight: 700;
          color: #00a0e6;

          ${medias.greaterThan("sm")`
              font-size: 11px;
            `}

          > span {
            padding: 7px 10px;
            margin-left: 10px;

            display: inline-block;
            background-color: rgba(0, 160, 230, 0.06);
            border-radius: 6px;
            font-size: 13px;
          }
        }
      }
    }
  }
`;
