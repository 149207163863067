import axios from "axios";
import config from "config";

export const api = axios.create({
  baseURL: config.BASE_URL,
});

export function postPaymentWeblink(payload, captcha) {
  return api.post("/payment", payload, { headers: { captcha } });
}

export function getValidationWeblink(uid) {
  return api.get(`/weblink/${uid}`);
}
