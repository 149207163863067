import styled from "styled-components";

import { medias } from "styles";

export const Container = styled.section``;

export const Box = styled.div`
  position: relative;

  margin-top: -36px;

  background: #fff;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0 12px 22px rgba(0, 0, 0, 0.06);

  ${medias.greaterThan("sm")`
    margin-top: -55px;
  `}
`;

export const Fieldset = styled.fieldset`
  position: relative;
  margin-top: 45px;
  padding: 30px 10px;

  border: solid 1px #f4f4f4;
  border-radius: 6px;

  ${medias.greaterThan("sm")`
    padding: 40px 50px;

  `}
`;

export const Legend = styled.legend`
  text-align: center;
  color: #2f5768;
  font-size: 12px;
  font-weight: 700;
  padding: 0 10px;
  text-transform: uppercase;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SubGrid = styled.div`
  display: flex;
  width: 100%;

  ${medias.greaterThan("sm")`
    width: 50%;
  `}
`;

export const AlertPorto = styled.div`
  ${medias.lessThan("sm")`
    display: none;
  `}
`;
