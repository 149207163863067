import { createSlice } from "@reduxjs/toolkit";
import { init as firebase } from "services/firebase";
import { api } from "services/api";

const login = createSlice({
  name: "login",
  initialState: {
    isAnonymous: false
  },
  reducers: {
    GetAnonymousUser: (state, action) => ({
      ...state,
      isAnonymous: action.payload.isAnonymous
    })
  }
});

export const getToken = async () =>
  firebase.auth() && (await firebase.auth().currentUser.getIdToken(true));

export async function AnonymousUser() {
  const credential = await firebase.auth().signInAnonymously();
  const token = await credential.user.getIdToken(true);
  api.defaults.headers.authorization = `Basic ${token}`;
}

export const { reducer, actions } = login;
