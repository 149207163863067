import React from "react";

import { Header } from "layout";

import { Alert } from "components";

import { Container } from "./styles";

function Error() {
  return (
    <>
      <Header noTitle />
      <Alert
        icon="alert-triangle"
        color="warning"
        description="Esse link não está mais disponível."
        description2="Em caso de dúvidas entre em contato com o seu Corretor ou Consultor."
      />
      <Container></Container>
    </>
  );
}

export default Error;
