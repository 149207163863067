import React from "react";
import { Provider } from "react-redux";
import { GlobalStyles } from "styles/global";

import store from "store";
import Router from "./routes";

function App() {
  return (
    <Provider store={store}>
      <main>
        <GlobalStyles />
        <Router />
      </main>
    </Provider>
  );
}

export default App;
