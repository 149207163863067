import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    show: false,
    message: "",
    type: "",
  },
  reducers: {
    setNotification: (state, action) => ({
      ...state,
      show: action.payload.show || false,
      message: action.payload.message || "",
      type: action.payload.type || "",
    }),
  },
});

export const { reducer, actions } = notificationsSlice;

// export default notificationsSlice.reducer;
