import React from "react";

import { Container, Label, Field, ErrorText } from "./styles";

const Input = React.forwardRef(
  (
    {
      label,
      type,
      name,
      error,
      placeholder,
      children,
      isCreditCard,
      isPhone,
      isDate,
      isShortDate,
      numberOnly,
      mask,
      prefix,
      ...props
    },
    ref
  ) => {
    const blocks = mask
      ? mask.match(/#+/g).map((each) => each.length)
      : [99999];
    const delimiters = mask
      ? mask.match(/[^#]/g)
      : isCreditCard
      ? [" "]
      : ["/"];
    const datePattern = isShortDate ? ["m", "y"] : ["d", "m", "Y"];

    const options = {
      blocks,
      delimiters,
      numericOnly: numberOnly,
      creditCard: isCreditCard,
      phone: isPhone,
      date: isDate,
      prefix,
      datePattern,
      phoneRegionCode: "BR",
    };

    return (
      <Container {...props}>
        <Label>{label}</Label>
        <Field
          {...props}
          type={type}
          name={name}
          htmlRef={ref}
          error={error}
          // success={success}
          placeholder={placeholder}
          options={options}
        />
        <ErrorText>{error}</ErrorText>
        {/* <SuccessText>{success}</SuccessText> */}
      </Container>
    );
  }
);

export default Input;
