import valid from "card-validator";
import * as yup from "yup";
import { isCpf } from "@pixter/utils/lib/validators";

const PaymentSchema = number => {
  let cvvLength = 3;

  const validate = valid.number(number);
  if (validate.isValid) cvvLength = validate.card.code.size;

  return yup.object().shape({
    number: yup
      .string()
      .test(
        "test-number",
        "Número de cartão inválido",
        value => valid.number(value).isValid
      )
      .required("Campo obrigatório"),
    name: yup.string().required("Campo obrigatório"),
    date: yup
      .string()
      .min(5, "Informe MM/AA")
      .test(
        "teste-date",
        "Data inválida",
        value => valid.expirationDate(value).isValid
      )
      .required("Data de validade inválida"),
    cvv: yup
      .string()
      .min(cvvLength, `O código de segurança deve ter ${cvvLength} dígitos`)
      .max(cvvLength, `O código de segurança deve ter ${cvvLength} dígitos`)
      .required("Código de segurança inválido"),
    doc: yup
      .string()
      .test("teste-doc", "CPF inválido", value => isCpf(value))
      .required("Campo obrigatório")
  });
};

export const placeholderLengh = number => {
  let cvvLength = 3;

  const validate = valid.number(number);
  if (validate.isValid) cvvLength = validate.card.code.size;

  return cvvLength === 3 ? "123" : "1234";
};

export const checkAllIsValid = async payload => {
  const validated = await PaymentSchema(payload.number)
    .validate(payload, {
      abortEarly: false
    })
    .catch(err => err);

  return validated;
};
