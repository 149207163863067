import styled from "styled-components";
import styledMap from "styled-map";

import { medias } from "styles";

export const Container = styled.div`
  position: relative;

  max-width: 700px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 30px 30px;
  margin-top: -36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 12px 22px rgba(0, 0, 0, 0.06);

  ${medias.greaterThan("sm")`
    margin-top: -55px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 175px;
  `}
`;

export const Ball = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  border-radius: 50%;

  background-color: ${styledMap("color", {
    success: "rgba(116,230,1,0.2)",
    warning: "rgba(255,199,0,0.2)",
    default: "rgba(0,0,0,0.1)"
  })};

  > svg {
    stroke: ${styledMap("color", {
      success: "#74E601",
      warning: "#FFC700",
      default: "#000"
    })};
  }
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: 15px;
  color: #7d8898;
`;
