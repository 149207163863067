import { createSlice } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { removeCaracteres } from "utils/remove-caracteres";
import { postPaymentWeblink, getValidationWeblink } from "services/api";
import { actions as notificationAction } from "store/reducers/notifications";

const weblink = createSlice({
  name: "weblink",
  initialState: {
    captcha: false,
    loading: true,
    loadingPost: false,
    successPost: false,
    error: null,
    success: null,
    data: {},
    notification: "error"
  },
  reducers: {
    setRecaptcha: (state, action) => ({
      ...state,
      captcha: action.payload
    }),
    dataWeblinkFetch: state => ({
      ...state,
      loading: true
    }),
    dataWeblinkSuccess: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload.data
    }),
    dataWeblinkError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error,
      data: {
        config: action.payload.config
      }
    }),
    dataSendPayment: state => ({
      ...state,
      loadingPost: true
    }),
    dataSendPaymentSuccess: (state, action) => ({
      ...state,
      loadingPost: false,
      successPost: true,
      messageSuccess: action.payload.message
    }),
    dataSendPaymentError: state => ({
      ...state,
      loadingPost: false,
      notification: "error"
    })
  }
});

export function dataWeblink(uid) {
  return async dispatch => {
    try {
      dispatch(weblink.actions.dataWeblinkFetch());
      const response = await getValidationWeblink(uid);

      dispatch(weblink.actions.dataWeblinkSuccess({ data: response.data }));
    } catch (error) {
      dispatch(
        weblink.actions.dataWeblinkError({
          error: error?.response?.data?.message,
          config: error?.response?.data?.config
        })
      );
    }
  };
}

export function PaymentWeblink(payment) {
  return async (dispatch, getState) => {
    try {
      dispatch(weblink.actions.dataSendPayment());
      const [month, year] = payment.date.split("/");
      const name = payment.name;
      const number = removeCaracteres(payment.number);
      const doc = removeCaracteres(payment.doc);
      const installment = payment.installment;
      const cvv = payment.cvv;
      const { data, captcha } = getState().weblink;

      const payload = {
        uid: data.uid,
        paymentData: {
          installments: installment,
          card: {
            name,
            doc,
            number,
            month,
            year,
            cvv
          }
        }
      };
      const response = await postPaymentWeblink(payload, captcha);
      if (response.data) {
        setTimeout(() => {
          dispatch(push("/sucesso"));
        }, 2000);
      }
      dispatch(
        notificationAction.setNotification({
          message: `Transação realizada com sucesso`,
          show: true,
          type: "success"
        })
      );
      dispatch(
        weblink.actions.dataSendPaymentSuccess({
          message: response?.data?.message
        })
      );
    } catch (error) {
      let message =
        error.code === "ECONNABORTED"
          ? "Oops, houve uma instabilidade, tente novamente."
          : "Não foi possível efetuar o pagamento, verifique os dados e tente novamente.";

      if (error?.response?.data?.status === 500) {
        message = "Erro interno no servidor, tente novamente mais tarde.";
      }

      dispatch(
        notificationAction.setNotification({
          message: error?.response?.data?.message || message,
          show: true,
          type: "error"
        })
      );
      dispatch(weblink.actions.dataSendPaymentError());
      dispatch(weblink.actions.setRecaptcha(""));
      throw error;
    }
  };
}

export const { reducer, actions } = weblink;
