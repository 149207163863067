import styled, { css } from "styled-components";

import { medias } from "styles";

export const Container = styled.div`
  display: flex;
  margin-top: 30px;

  ${medias.lessThan("sm")`
    display: none;
  `}
`;

export const Ball = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 160, 230, 0.2);
  margin-right: 8px;
  color: #00a0e6;

  ${({ isPorto }) =>
    isPorto &&
    css`
      background: linear-gradient(to right, #00a0e6 0%, #29b4f1 100%);
    `}
`;

export const Text = styled.p`
  font-size: 11px;
  color: #2f5768;
  flex: 1;
`;

export const Price = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #00a0e6;
`;
