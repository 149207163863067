import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { configureStore, combineReducers } from "@reduxjs/toolkit";

import thunk from "redux-thunk";

import * as reducers from "./reducers";

export const history = createBrowserHistory();

const store = configureStore({
  reducer: combineReducers({
    router: connectRouter(history),
    ...reducers
  }),
  middleware: [thunk, routerMiddleware(history)]
});

export default store;
