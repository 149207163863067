import React, { Fragment } from "react";
import * as Icons from "react-feather";

import { toPascalCase } from "utils/strings";

/**
 * Feather Icons
 * @see https://feathericons.com/
 * @param {Icons.Props} props
 */
export default function Icon({ name, ...props }) {
  const FeatherIcon = Icons[toPascalCase(name)] || Fragment;
  return <FeatherIcon {...props} />;
}

/**
 * @type {Icons.Props}
 */
Icon.defaultProps = {
  size: 18
};
