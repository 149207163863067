import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Cards from "react-credit-cards";
import Media from "react-media";

import "react-credit-cards/es/styles-compiled.css";

import config from "../../../../config";

import { useDispatch, useSelector } from "react-redux";

import { PaymentWeblink, actions } from "store/reducers/weblink";

import { PORTO_CARD_INITIAL } from "utils/porto";

import { Input, Grid, Select, Icons } from "components";

import { formatCurrency } from "helpers/currency";

import { Button, Security, Porto, Discont } from "../../components";

import { Container, TextDiscont, Price } from "./styles";

import { checkAllIsValid, placeholderLengh } from "./validation";

function Form() {
  const dispatch = useDispatch();
  const refCaptcha = useRef(null);

  const [placeholder, setPlaceholder] = useState(placeholderLengh(""));

  const [payload, setPayload] = useState({
    number: "",
    name: "",
    doc: "",
    date: "",
    cvv: "",
    installment: "1",
  });

  const [alreadyAdded, setAlreadyAdded] = useState({
    number: false,
    name: false,
    doc: false,
    date: false,
    cvv: false,
    installment: false,
  });

  const [errors, setErrors] = useState({});
  const [index, setIndex] = useState(1);

  const [focus, setFocus] = useState("");
  const {
    weblink: { data, loadingPost, captcha },
    notifications,
  } = useSelector((state) => state);

  const [isPorto, setIsPorto] = useState(false);
  const [installments, setInstallments] = useState([]);

  const onSubmit = (e) => {
    dispatch(PaymentWeblink(payload));
    e.preventDefault();
  };

  function verifyCaptcha(recaptchaToken) {
    dispatch(actions.setRecaptcha(recaptchaToken));
  }

  const debounce = {
    timeout: 500,
    timer: null,
  };

  function checkCardPorto(event) {
    setPayload({
      ...payload,
      number: event.target.value,
    });

    if (event) {
      clearTimeout(debounce.timer);
      const card = event.target.value;
      const cardReplace = card.replace(/\s/g, "");
      debounce.timer = setTimeout(() => {
        const isPortoCard = PORTO_CARD_INITIAL.some((initial) =>
          cardReplace.includes(initial)
        );
        setIsPorto(isPortoCard);
      }, debounce.timeout);
    }

    if (Object.keys(data.prices).includes("porto")) return false;
  }

  function handleInputFocus(e) {
    const focused = e.target.name === "cvv" ? "cvc" : e.target.name;
    setFocus(focused);

    // handleAlreadyAdded(e.target.name, e, true);
  }

  function handleAlreadyAdded(name, e, focus) {
    setFocus("");

    const value = e.target.value;

    if (value || focus) {
      setAlreadyAdded({
        ...alreadyAdded,
        [name]: focus ? false : true,
      });
    }
  }

  function handleInputChange(name, e) {
    const value = e.target.value;

    setPayload({
      ...payload,
      [name]: value,
    });
  }

  async function checkAllFieldsPayloadIsValid() {
    const validated = await checkAllIsValid(payload);

    let erros = {};

    if (validated && validated.inner) {
      for (const item of validated.inner) {
        erros = {
          ...erros,
          [item.path]: item.message,
        };
      }
    }

    setErrors(erros);
  }

  useEffect(() => {
    checkAllFieldsPayloadIsValid();
    setPlaceholder(placeholderLengh(payload.number));
  }, [payload]);

  function verifyInstallments() {
    if (isPorto) setInstallments(data?.prices?.porto?.installments);
    else setInstallments(data?.prices?.others?.installments);

    const { installment } = payload;
    const current = parseFloat(installment);

    console.log(current);

    if (current > 4 && !isPorto)
      setPayload({
        ...payload,
      });
  }

  useEffect(() => {
    if (notifications?.type === "error") refCaptcha.current.reset();
  }, [notifications]);

  useEffect(verifyInstallments, [isPorto]);

  return (
    <Container onSubmit={onSubmit}>
      <Media query="(max-width: 980px)">
        <>
          {console.log(data?.prices?.porto)}
          {data?.prices?.porto &&
            Object.keys(data?.prices).includes("porto") &&
            data?.prices?.porto?.amount !== data?.prices?.others?.amount && (
              <Discont data={data} />
            )}
          <Cards
            cvc={payload.cvv}
            expiry={payload.date}
            focused={focus}
            name={payload.name || "NOME E SOBRENOME"}
            number={payload.number}
            placeholder={{ name: "NOME E SOBRENOME" }}
          />
          <br />
          <br />
        </>
      </Media>
      <Grid gridTemplateColumns="48% 48%">
        <Input
          label="Número do cartão"
          placeholder="0000 0000 0000 0000"
          isCreditCard
          name="number"
          error={!(focus === "number") && alreadyAdded.number && errors.number}
          onChange={checkCardPorto}
          onBlur={(e) => handleAlreadyAdded("number", e)}
          disabled={loadingPost}
          onFocus={handleInputFocus}
        />

        <Media query="(min-width: 981px)">
          {data?.prices?.porto &&
            Object.keys(data?.prices).includes("porto") &&
            data?.prices?.porto?.amount !== data?.prices?.others?.amount && (
              <Discont data={data} />
            )}
        </Media>
      </Grid>

      <Grid gridTemplateColumns="48% 48%">
        <Grid gridGap="0">
          <Input
            label="Nome impresso no cartão"
            onChange={(e) => handleInputChange("name", e)}
            onBlur={(e) => handleAlreadyAdded("name", e)}
            placeholder="NOME E SOBRENOME"
            name="name"
            error={!(focus === "name") && alreadyAdded.name && errors.name}
            disabled={loadingPost}
            onFocus={handleInputFocus}
          />

          <Grid gridTemplateColumns="49% 49%">
            <Input
              label="Data de validade"
              isDate
              isShortDate
              placeholder="MM/AA"
              name="date"
              error={!(focus === "date") && alreadyAdded.date && errors.date}
              disabled={loadingPost}
              onFocus={handleInputFocus}
              onChange={(e) => handleInputChange("date", e)}
              onBlur={(e) => handleAlreadyAdded("date", e)}
            />
            <Input
              label="Código de segurança"
              name="cvv"
              maxLength={placeholder === "123" ? 3 : 4}
              placeholder={placeholder}
              error={!(focus === "cvc") && alreadyAdded.cvv && errors.cvv}
              disabled={loadingPost}
              onFocus={handleInputFocus}
              onChange={(e) => handleInputChange("cvv", e)}
              onBlur={(e) => handleAlreadyAdded("cvv", e)}
            />
          </Grid>
        </Grid>

        <Media query="(min-width: 981px)">
          <div>
            <Cards
              cvc={payload.cvv}
              expiry={payload.date}
              focused={focus}
              name={payload.name || "NOME E SOBRENOME"}
              number={payload.number}
              placeholder={{ name: "NOME E SOBRENOME" }}
            />
          </div>
        </Media>
      </Grid>
      <Grid gridTemplateColumns="48% 48%">
        <Input
          label="CPF"
          mask="###.###.###-##"
          placeholder="000.000.000-00"
          onChange={(e) => handleInputChange("doc", e)}
          onBlur={(e) => handleAlreadyAdded("doc", e)}
          name="doc"
          error={!(focus === "doc") && alreadyAdded.doc && errors.doc}
          disabled={loadingPost}
          onFocus={handleInputFocus}
        />
        <Media query="(min-width: 981px)">
          {data?.prices?.porto &&
            isPorto &&
            Object.keys(data?.prices).includes("porto") &&
            data?.prices?.porto?.amount !== data?.prices?.others?.amount && (
              <TextDiscont ml={60}>
                <Icons name="check" color="#74E601" />
                Você usou <span>Cartão Porto Seguro.</span> <br /> Agora sua
                compra fica:
                <Price>R$ {formatCurrency(data.prices?.porto?.amount)}</Price>
              </TextDiscont>
            )}
        </Media>
      </Grid>
      <Grid gridTemplateColumns="48% 48%">
        <Grid>
          <Media query="(max-width: 980px)">
            {data?.prices?.porto &&
              isPorto &&
              Object.keys(data?.prices).includes("porto") &&
              data?.prices?.porto?.amount !== data?.prices?.others?.amount && (
                <TextDiscont mb={30}>
                  <Icons name="check" color="#74E601" />
                  Você usou <span>Cartão Porto Seguro.</span> <br />
                  Agora sua compra fica:
                  <Price>R$ {formatCurrency(data.prices?.porto?.amount)}</Price>
                </TextDiscont>
              )}
          </Media>

          <Select
            label="Parcelas"
            name="installment"
            disabled={loadingPost}
            onFocus={handleInputFocus}
            onChange={(e) => handleInputChange("installment", e)}
            onBlur={(e) => handleAlreadyAdded("installment", e)}
          >
            {installments?.map((item) => (
              <>
                <option key={item.value} value={item.quantity}>
                  {item.quantity}x R$ {formatCurrency(item.value)}
                </option>
              </>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid>
        <ReCAPTCHA
          ref={refCaptcha}
          sitekey={config.CAPTCHA}
          onChange={verifyCaptcha}
        />
      </Grid>
      <Security />
      <Button disabled={!captcha || Object.keys(errors).length} type="submit" />
    </Container>
  );
}

export default Form;
