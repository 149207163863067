import styled from "styled-components";
import { medias } from "styles";

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-gap: ${(props) => props.gridGap || "4%"};

  ${medias.lessThan("sm")`
    grid-template-columns: 100%;
  `}
`;
