import styled, { keyframes } from "styled-components";
import { rgba } from "polished";

const spin = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;

  position: fixed;

  z-index: ${({ open }) => (open ? 99 : -1)};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: 0.3s;
`;

export const Close = styled.div`
  cursor: pointer;
  color: #2f5768;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${rgba("#000", 0.3)};

  z-index: 1;
`;

export const Content = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 50px 110px;

  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Info = styled.div`
  background: rgba(0, 160, 230, 0.1);
  width: 90px;
  height: 90px;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #00a0e6;
`;

export const Title = styled.h1`
  font-size: 21px;
  font-weight: 700;
  color: #2f5768;
  text-align: center;
  margin: 10px 0 23px 0;
`;

export const Button = styled.button`
  background: #00a0e6;
  border-radius: 12px;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;

  color: #ffffff;

  padding: 17px 54px 18px 54px;
  border: none;
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: 0.3s;

  display: flex;
  position: relative;
`;

export const Loading = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${spin} 2s linear infinite;
  opacity: ${({ loading }) => (loading ? 1 : 0)};

  position: absolute;
  right: 10px;
  top: 15px;
`;
