import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { medias } from "styles";

export const variants = {
  initial: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 2,
    },
  },
  active: {
    scale: 20,
    opacity: 0,
    transition: {
      duration: 10,
    },
  },
};

export const variantsLoader = {
  initial: {
    opacity: 0,
  },
  active: {
    active: 1,
  },
};

export const variantesIcon = {
  initial: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const Container = styled(motion.button)`
  position: absolute;
  bottom: -30px;
  left: 50%;

  width: 255px;
  height: 60px;

  background: linear-gradient(to right, #00a0e6 0%, #29b4f1 100%);
  border-radius: 12px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-indent: 40px;
  transition: 0.3s;
  transform: translate(-50%);
  box-shadow: 0 12px 20px rgba(0, 160, 230, 0.2);
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  :hover {
    transition: 0.3s;
    box-shadow: 0 16px 20px rgba(0, 160, 230, 0.3);
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      color: transparent !important;
    `};

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      background: #74e601;
      box-shadow: 0 16px 20px rgba(116, 230, 1, 0.3);

      ${Ball} {
        transform: scale(10) !important;
        background: #74e601;
      }

      ${Icon} {
        left: 43%;
        top: 53%;
        transform: translate(-50%, -50%);
      }
    `};

  ${medias.greaterThan("sm")`
    right: 50px;
    left: auto;
    transform: translate(0);
  `}
`;

export const Ball = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: -40px;

  width: 96px;
  height: 96px;
  margin-top: -48px;

  border-radius: 50%;
  background: #24b5f4;
`;

export const Loader = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Icon = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: -23px;
  transform: translateY(-35%);
`;
