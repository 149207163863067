export function toCamelCase(text) {
  return text.replace(/-\w/g, clearAndUpper);
}

export function toPascalCase(text) {
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
}

export function clearAndUpper(text) {
  return text.replace(/-/, "").toUpperCase();
}
