import React, { useState } from "react";

import { Icons, Input } from "components";

import {
  Container,
  Overlay,
  Content,
  Title,
  Button,
  Close,
  Info,
  Loading,
} from "./styles";

function ModalCoupon({ open, handleGetCoupon, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  async function getCoupon() {
    setLoading(true);
    try {
      await handleGetCoupon(value);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  return (
    <Container open={open}>
      <Overlay onClick={handleClose} />

      <Content>
        <Close onClick={handleClose}>
          <Icons name="x" />
        </Close>
        <Info>
          <Icons size={26} name="tag" />
        </Info>
        <Title>Digite aqui seu cupom de desconto</Title>

        <Input
          value={value}
          onChange={({ target }) => setValue(target.value)}
          name="coupon"
          disabled={false}
        />
        <Button disabled={loading} onClick={getCoupon}>
          Adicionar cupom
          <Loading loading={loading}>
            <Icons name="loader" />
          </Loading>
        </Button>
      </Content>
    </Container>
  );
}

export default ModalCoupon;
