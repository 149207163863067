import React from "react";

import { Icons } from "components";

import { Container } from "./styles";

function Copyright() {
  return <Container></Container>;
}

export default Copyright;
