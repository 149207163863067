import React from "react";

import { Container, Label, Field, ErrorText, SuccessText } from "./styles";

const Select = React.forwardRef(
  (
    { label, type, name, error, success, placeholder, children, ...props },
    ref
  ) => {
    return (
      <Container {...props}>
        <Label>{label}</Label>
        <Field
          {...props}
          type={type}
          name={name}
          ref={ref}
          error={error}
          success={success}
          placeholder={placeholder}
        >
          {children}
        </Field>
        <ErrorText>{error}</ErrorText>
        <SuccessText>{success}</SuccessText>
      </Container>
    );
  }
);
export default Select;
