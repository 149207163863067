import styled from "styled-components";

import { medias } from "styles";

export const Container = styled.div`
  margin-top: 70px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  color: #7d8898;
  text-align: center;

  > svg {
    margin-right: 8px;

    ${medias.lessThan("sm")`
        width: 35px;
        height: 35px;
    `}
  }
`;
