import React from "react";

import { Header } from "layout";

import { Alert } from "components";

import { Container } from "./styles";

function Success() {
  return (
    <>
      <Header noLoading isSuccess />
      <Alert icon="check" color="success" description="" />
      <Container></Container>
    </>
  );
}

export default Success;
