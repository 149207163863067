import styled, { css } from "styled-components";

import { medias } from "styles";

export const Container = styled.div`
  position: relative;

  width: 100%;
  padding-right: 0.938rem;
  padding-left: 0.938rem;
  margin-right: auto;
  margin-left: auto;

  ${medias.greaterThan("md")`
    max-width: 45rem;
  `};

  ${medias.greaterThan("lg")`
    max-width: 60rem;
  `};

  ${({ isFluid }) =>
    isFluid &&
    css`
      max-width: inherit !important;
      ${medias.greaterThan("xl")`
        padding-left: 4.063rem;
        padding-right: 4.063rem;
      `};
    `}
`;
