import React from "react";
import { useSelector } from "react-redux";

import { Icons } from "components";

import { formatCurrency } from "helpers/currency";

import { Container, Ball, Text, Price } from "./styles";

function Porto({ isPorto }) {
  const { data } = useSelector((state) => state.weblink);

  return (
    <Container>
      {isPorto ? (
        <>
          <Ball isPorto>
            <Icons name="check" color="#fff" />
          </Ball>
          <Text>
            Desconto Cartão Porto Seguro <br /> aplicado, sua compra agora é{" "}
            <Price>R$ {formatCurrency(data.prices?.porto?.amount)}</Price>
          </Text>
        </>
      ) : (
        <>
          <Ball>
            <Icons name="percent" />
          </Ball>
          <Text>
            Utilizando o seu <br /> cartão Porto Seguro <br /> a sua compra fica{" "}
            <Price>R$ {formatCurrency(data.prices?.porto?.amount)}</Price>
          </Text>
        </>
      )}
    </Container>
  );
}

export default Porto;
