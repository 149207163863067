import styled from "styled-components";

import { medias } from "styles";

export const Container = styled.div`
  margin-bottom: 25px;

  ${medias.greaterThan("sm")`
    margin-bottom: 35px;
  `}
`;

export const Label = styled.label`
  margin-bottom: 10px;
  display: block;

  font-size: 13px;
  font-weight: 300;
  color: #7d8898;
`;

export const Field = styled.select`
  height: 52px;
  width: 100%;

  border: solid 1px #eaeaea;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  color: #2f5768;
  padding: 0 25px;

  :disabled {
    background-color: #eaeaea;
  }

  :focus {
    border-color: #00a0e6;
  }
`;

export const ErrorText = styled.span``;

export const SuccessText = styled.span``;
