export const BASE_URL = "https://api-stg.pagamento.portosegurofaz.com.br";

export const URL_PROJECT = "https://pagamento-stg.firebaseapp.com";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBiZjx1lNPscRyB2SIqCUOF1eC3XfbsBtY",
  authDomain: "portosegurofaz-pagamento-stg.firebaseapp.com",
  databaseURL: "https://portosegurofaz-pagamento-stg.firebaseio.com",
  projectId: "portosegurofaz-pagamento-stg",
  storageBucket: "portosegurofaz-pagamento-stg.appspot.com",
  messagingSenderId: "1022558889726",
  appId: "1:1022558889726:web:c0941030ea1d16961be7af",
  measurementId: "G-JC2NC2GDQK",
};

export const CAPTCHA = "6Lfa9voUAAAAAGi-5mFJSMxPhsbbM30KWzg-OAxB";
