import React from "react";

import { Container, Ball, Percent, Text, Title } from "./styles";

function Discont({ data }) {
  return (
    <Container>
      <Ball>
        <Text>até</Text>
        <Percent>
          {parseInt(
            100 -
              (100 / data.prices?.others?.amount) * data.prices?.porto?.amount
          )}
          %
        </Percent>
      </Ball>
      <Title>
        Digite o número do seu <span>Cartão de Crédito</span> <br />{" "}
        <span>Porto Seguro</span> para garantir o desconto.
      </Title>
    </Container>
  );
}

export default Discont;
