import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AnonymousUser } from "store/reducers/login";

import { dataWeblink } from "store/reducers/weblink";

import { Header } from "layout";

import { ContainerDefault, Alert } from "components";

import { Table, Copyright, Form } from "./components";

import { Container, Box, Fieldset, Legend } from "./styles";

function Home() {
  const dispatch = useDispatch();
  const { uid } = useParams();

  const { error, loading } = useSelector(state => state.weblink);

  useEffect(() => {
    getDataWeblink();
  }, []);

  async function getDataWeblink() {
    await AnonymousUser();
    dispatch(dataWeblink(uid));
  }
  return (
    <>
      <Header isError isWeblink />
      {!loading &&
        (error ? (
          <Alert
            icon="alert-triangle"
            color="warning"
            description="Esse link não está mais disponível."
            description2="Em caso de dúvidas entre em contato com o seu Corretor ou Consultor."
          />
        ) : (
          <>
            <Container>
              <ContainerDefault>
                <Box>
                  <Table />
                </Box>
                <Fieldset>
                  <Legend>meio de pagamento</Legend>
                  <Form />
                </Fieldset>
                <Copyright />
              </ContainerDefault>
            </Container>
          </>
        ))}
    </>
  );
}

export default Home;
