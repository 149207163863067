import styled from "styled-components";

import { medias } from "styles";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
`;

export const Ball = styled.div`
  position: relative;

  width: 50px;
  height: 50px;
  margin-right: 5px;

  background: rgba(0, 160, 230, 0.2);
  border-radius: 50%;

  ${medias.lessThan("sm")`
    width: 46px;
    height: 46px;
  `}
`;

export const Text = styled.p`
  color: #000000;
  font-size: 13px;
  font-weight: 700;
  transform: translateX(-10px);

  ${medias.lessThan("sm")`
    font-size: 11px;
  `}
`;

export const Percent = styled.p`
  color: #00a0e6;
  font-size: 26px;
  font-weight: 700;
  transform: translateX(-10px);

  ${medias.lessThan("sm")`
    font-size: 24px;
  `}
`;

export const Title = styled.p`
  font-size: 13px;
  color: #2f5768;

  > span {
    font-weight: 700;
  }

  ${medias.lessThan("sm")`
    font-size: 11px;
    `}
`;
