import React from "react";
import PropTypes from "prop-types";

import { Container as ContainerBase } from "./styles";

function Container({ children, ...props }) {
  return <ContainerBase {...props}>{children}</ContainerBase>;
}

Container.defaultProps = {
  isFluid: false
};

Container.propTypes = {
  isFluid: PropTypes.bool
};

export default Container;
