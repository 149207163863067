import React from "react";

import { Icons } from "components";

import { Container, Ball } from "./styles";

function Security() {
  return (
    <Container>
      <Ball>
        <Icons name="shield" />
      </Ball>
      Ambiente seguro
    </Container>
  );
}

export default Security;
