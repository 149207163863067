import styled from "styled-components";
import { space } from "styled-system";

import { Container as Input } from "components/Input/styles";
import { Container as Grid } from "components/Grid/styles";

import { medias } from "styles";

export const Container = styled.form`
  /* ${Grid} {
    ${Grid} {
      ${Input} {
        :nth-of-type(1) {
          margin-bottom: 15px;
        }
      }
    }
  } */

  .rccs__cvc.rccs--focused {
    border: 2px solid #f00;
    padding: 6px;
    transform: translate(-13px, -11px);

    height: 30px;
    min-width: 50px;

    border-radius: 100%;
    text-align: center;
  }

  .rccs__cvc__front.rccs--focused {
    border: 2px solid #f00;
    padding: 6px;
    transform: translate(10px, -8px);

    height: 30px;
    min-width: 50px;

    border-radius: 100%;
    text-align: center;
  }
`;

export const TextDiscont = styled.div`
  ${space}
  font-size: 11px;
  color: #7d8898;

  > svg {
    display: inline-block;
    transform: translateY(5px);
    margin-right: 5px;
  }

  > span {
    font-weight: 500;
  }
`;

export const Price = styled.span`
  font-size: 15px;
  font-weight: bold !important;
  color: #00a0e6;
  margin-left: 5px;
`;
