import React from "react";

import { Icons } from "components";

import { Container, Ball, Text } from "./styles";

function Alert({ description, description2, icon, color, size }) {
  return (
    <Container>
      <Ball color={color}>
        <Icons name={icon ? icon : "help-circle"} color={color} size={size} />
      </Ball>
      <Text>{description}</Text>
      <Text>{description2}</Text>
    </Container>
  );
}

export default Alert;
