import styled, { css } from "styled-components";
import { medias } from "styles";
import Cleave from "cleave.js/react";

export const Container = styled.div`
  margin-bottom: 25px;
  position: relative;

  ${medias.greaterThan("sm")`
    margin-bottom: 25px;
  `}
`;

export const Label = styled.label`
  margin-bottom: 10px;
  display: block;

  font-size: 13px;
  font-weight: 300;
  color: #7d8898;
`;

export const Field = styled(Cleave)`
  padding: 25px;
  max-height: 45px;
  width: 100%;

  border: solid 1px #eaeaea;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  color: #2f5768;

  :disabled {
    background-color: #eaeaea;
  }

  ::placeholder {
    color: #ccc;
  }

  :focus {
    border-color: #00a0e6;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #f22563;
    `}
`;

export const ErrorText = styled.span`
  position: absolute;
  top: 104%;
  left: 0;

  width: 100%;

  font-size: 11px;
  color: #f22563;
  font-weight: 500;
`;

export const SuccessText = styled.span``;
