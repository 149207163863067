export const PORTO_CARD_INITIAL = [
  "412177",
  "415274",
  "415275",
  "484635",
  "446690",
  "536380",
  "536537",
  "532930",
  "553659",
  "512452",
  "519873"
];
