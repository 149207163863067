import styled from "styled-components";

export const Container = styled.div`
  height: ${props => props.size || "2.3rem"};
  width: ${props => props.size || "2.3rem"};

  animation: spinAround 0.5s infinite linear;
  /* border: 2px solid #00a0e6; */
  border: 2px solid ${props => props.color || "#00a0e6"};
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
