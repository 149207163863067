export const BASE_URL = "https://api.pagamento.portosegurofaz.com.br";

export const URL_PROJECT = "https://pagamento-prd.firebaseapp.com";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBC8QWVYpM-aXo7q4GgBskoSTpj3_UcDQ4",
  authDomain: "portosegurofaz-pagamento-prd.firebaseapp.com",
  projectId: "portosegurofaz-pagamento-prd",
  storageBucket: "portosegurofaz-pagamento-prd.appspot.com",
  messagingSenderId: "1065509234880",
  appId: "1:1065509234880:web:7d5c3eda487f3ba7ce6525",
  measurementId: "G-2BVKE17J5X",
};

export const CAPTCHA = "6LeR-PoUAAAAAFl-U0l_0QoldbjcpKoqiAj2P3y0";
